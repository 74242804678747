import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8b02d2be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "promo-code" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.state.codeInputFormShow)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_a_typography_text, { class: "promo-code__header" }, {
                default: _withCtx(() => [
                  _createTextVNode(" У вас есть Инвайт/Промо код? ")
                ]),
                _: 1
              }),
              _createVNode(_component_a_button, {
                block: "",
                onClick: _ctx.onOpenInputCode
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Ввести код ")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_a_button, {
                block: "",
                onClick: _ctx.onRejectCode
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Продолжу без кода ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_a_typography_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Введите код ")
                ]),
                _: 1
              }),
              _createVNode(_component_a_input, {
                value: _ctx.state.code,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.code) = $event)),
                onInput: _ctx.changeCodeValue
              }, null, 8, ["value", "onInput"]),
              _createVNode(_component_a_button, {
                block: "",
                onClick: _ctx.apply
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Ввести код ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ], 64))
      ])
    ]),
    _: 1
  }))
}